<template>
  <div class="container-fluid">
    <div class="row" v-if="type != '' && hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
            <button class="btn btn-lg btn-primary" @click="hamla = '1'; getcamps()">
                البشائر
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
            <button class="btn btn-lg btn-success" @click="hamla = '2'; getcamps()">
                الميسر
            </button>
        </div>
    </div>
    <div class="row" v-if="type == '' && hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="type_1">
            <button class="btn btn-lg btn-primary" @click="type = '1';">
                منى / مكة
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="type_2">
            <button class="btn btn-lg btn-success" @click="type = '2';">
                عرفات
            </button>
        </div>
    </div>
    <div class="row" v-if="type != '' && hamla != ''">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        السكن ({{ type == '1' ? 'منى / مكة' : 'عرفات' }})
                        <button class="btn btn-primary" v-b-modal.add style="padding: 10px; padding-top: 8px; padding-right: 8px; border-radius: 50%; height: 30px; width: 30px;">
                            <i class="fa fa-plus"></i>
                        </button>
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <th>
                                    العنوان
                                </th>
                                <th>
                                    تفاصيل/ملاحظات
                                </th>
                                <th>
                                    الحد الاقصى
                                </th>
                                <th>
                                    الجنس
                                </th>
                                <th>
                                    خيارات
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="bus in camps" :key="bus._id">
                                    <td>
                                        {{ bus.title }}
                                    </td>
                                    <td>
                                        {{ bus.details }}
                                    </td>
                                    <td>
                                        {{ bus.limit }}
                                    </td>
                                    <td>
                                        {{ bus.gender == 1 ? 'للرجال' : 'للنساء' }}
                                    </td>
                                    <td>
                                        <b-dropdown dropleft style="width: 100%;"
                                            id="dropdown-1x"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            text="خيارات"
                                            variant="secondary"
                                            size="sm"
                                        >
                                            <b-dropdown-item @click="current = bus" v-b-modal.edit>
                                                <i class="fa fa-edit"></i>
                                                تعديل السكن
                                            </b-dropdown-item>
                                            <b-dropdown-item style="font-size: 12px" @click="deleteUser(bus._id)">
                                                <i class="fa fa-trash"></i>
                                                حذف السكن
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="add" title="اضافة سكن" hide-footer>
        <div class="form-group">
            <label for="">
                الاسم
            </label>
            <input type="text"
                class="form-control" v-model="create.title">
            </div>
            <div class="form-group">
            <label for="">
                اللوكيشن
            </label>
            <input type="text"
                class="form-control" v-model="create.location">
            </div>
            <div class="form-group">
            <label for="">
                تفاصيل/ملاحظات
            </label>
            <input type="text"
                class="form-control" v-model="create.details">
            </div>
            <div class="form-group">
            <label for="">
                الحد الاقصى للحجاج في السكن
            </label>
            <input type="number"
                class="form-control" v-model="create.limit">
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="create.gender" :value="1">
                للرجال
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="create.gender" :value="2">
                للنساء
              </label>
            </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="createNow()">
                اضافة السكن
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل" hide-footer>
        <template v-if="current._id">
            <div class="form-group">
            <label for="">
                الاسم
            </label>
            <input type="text"
                class="form-control" v-model="current.title">
            </div>
            <div class="form-group">
            <label for="">
                اللوكيشن
            </label>
            <input type="text"
                class="form-control" v-model="current.location">
            </div>
            <div class="form-group">
            <label for="">
                تفاصيل/ملاحظات
            </label>
            <input type="text"
                class="form-control" v-model="current.details">
            </div>
            <div class="form-group">
            <label for="">
                الحد الاقصى للحجاج في السكن
            </label>
            <input type="number"
                class="form-control" v-model="current.limit">
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="current.gender" :value="1">
                للرجال
              </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" v-model="current.gender" :value="2">
                للنساء
              </label>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="save()">
                    تعديل السكن
                </button>
            </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            camps: [],
            user: JSON.parse(localStorage.getItem("user")),
            create: {},
            hamla: "",
            type: "",
            hamla: "",
            hamla_2: checkPer("hamla_2"),
            hamla_1: checkPer("hamla_1"),
            type_1: checkPer('camps_1'),
            type_2: checkPer('camps_2')
        }
    },
    created(){
        if(!checkPer('camps')){
            this.$router.push('/pers')
            return;
        }
    },
    methods: {
        getcamps(){
            var g = this;
            $.post(api + '/admin/camps/list', {
                jwt: g.user.jwt,
                type: g.type,
                hamla: g.hamla
            }).then(function(r){
                g.camps = r.response;
            })
        },
        createNow(){
            var g = this;
            g.create.type = g.type;
            g.create.hamla = g.hamla;
            $.post(api + '/admin/camps/add', {
                jwt: g.user.jwt,
                obj: g.create
            }).then(function(r){
                g.getcamps();
                alert("تم الاضافة بنجاح", 100)
            })
        },
        deleteUser(id){
            if(confirm("متأكد من حذف السكن؟")){
                var g = this;
                $.post(api + '/admin/camps/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(a){
                    g.getcamps();
                    alert("تم الحذف بنجاح", 100);
                })
            }
        },
        save(){
            var g = this;
            g.current.type = g.type;
            g.current.hamla = g.hamla;
            $.post(api + '/admin/camps/edit', {
                jwt: g.user.jwt,
                id: g.current._id,
                obj: g.current
            }).then(function(a){
                g.getcamps();
                alert("تم التعديل بنجاح", 100);
            })
        }
    }
}
</script>

<style>
#dropdown-1x__BV_toggle_{
    padding: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-radius: 0px;
}
</style>